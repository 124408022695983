import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) { }
    register(name: string, email: string, password: string, cpassword: any, phoneNumber: any, phone: any, company: any, address1: any, address2: any, country: any, state
        : any, city: any, refrral: any) {
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/dropboss/register`, { name: name, email: email, password: password, cpassword: cpassword, company: company, phoneNumber: phoneNumber, phone: phone, address1: address1, address2: address2, country: country, state: state, city: city, refrral: refrral }).pipe(map(user => {
            // login successful if there's a jwt token in the response
            // return false;
            if (user.status.code == 0) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.user.status);
                localStorage.setItem(`${environment.appName}` + '_domain', '');
            }

            return user;
        }));
    }

    login(email: string, password: string) {

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/login`, { email: email, password: password }).pipe(map(user => {
            // login successful if there's a jwt token in the response
            // return false;
            if (user.status.code == 0) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.data.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.data.user.role_id);
                localStorage.setItem(`${environment.appName}` + '_status', user.data.user.status);
                localStorage.setItem(`${environment.appName}` + '_domain', user.data.user.shop ? user.data.user.shop.domain : '');


            }

            return user;
        }));

    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem(`${environment.appName}` + 'vendor_user');
        localStorage.removeItem(`${environment.appName}` + '_role');
        localStorage.removeItem(`${environment.appName}` + '_user');
        localStorage.removeItem(`${environment.appName}` + '_adminName');
        localStorage.removeItem(`${environment.appName}` + '_adminId');
        localStorage.removeItem(`${environment.appName}` + '_status');
        localStorage.removeItem(`${environment.appName}` + '_domain');
    }

    getActive(): Observable<any> {

        return this.http.get<any>(`http://3.6.99.136:3000/api/v1/hotel-software`).pipe(map(user => user));

    }
}