import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login';
import { AuthSuperadminGuard, AuthVendorGuard } from './_guards';
import { SiteFooterComponent } from './_layouts/site-footer/site-footer.component';
import { SiteHeaderComponent } from './_layouts/site-header/site-header.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { FrontDeskComponent } from './front-desk/front-desk.component';
import { DatePipe } from '@angular/common';
import { CheckinComponent } from './checkin/checkin.component';
import { ReservationComponent } from './reservation/reservation.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { EditCheckinComponent } from './edit-checkin/edit-checkin.component';
import { ExtraServiceComponent } from './extra-service/extra-service.component';
import { EditReservationComponent } from './edit-reservation/edit-reservation.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { FloorMasterComponent } from './floor-master/floor-master.component';
import { AddRoomTypeComponent } from './add-room-type/add-room-type.component';
import { AddRoomMasterComponent } from './add-room-master/add-room-master.component';
import { AddTaxMasterComponent } from './add-tax-master/add-tax-master.component';
import { AddItemMasterComponent } from './add-item-master/add-item-master.component';
import { AddRateTypeComponent } from './add-rate-type/add-rate-type.component';
import { FoodServiceComponent } from './food-service/food-service.component';
import { AddFoodMasterComponent } from './add-food-master/add-food-master.component';
import { AddLaundryItemMasterComponent } from './add-laundry-item-master/add-laundry-item-master.component';
import { LaundryServiceComponent } from './laundry-service/laundry-service.component';
import { OccupiedRoomsReportComponent } from './occupied-rooms-report/occupied-rooms-report.component';
import { ReservedRoomsReportComponent } from './reserved-rooms-report/reserved-rooms-report.component';
import { OnlinebookComponent } from './online-book/online-book.component';
import { CreateUserComponent } from './create-user/create-user.component';

const appRoutes: Routes = [

  {
    path: '',
    component: FrontDeskComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
   canActivate: [AuthSuperadminGuard]
  },
  {
    path: 'dashboard',
    component: HomeComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
   canActivate: [AuthSuperadminGuard]
  },
  {
    path: 'online-book',
    component: OnlinebookComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
   canActivate: [AuthSuperadminGuard]
  },
  {
    path: 'occupied-report',
    component: OccupiedRoomsReportComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
   canActivate: [AuthSuperadminGuard]
  },
  {
    path: 'reserved-report',
    component: ReservedRoomsReportComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
   canActivate: [AuthSuperadminGuard]
  },
  {
    path: 'add-room-type',
    component: AddRoomTypeComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
   canActivate: [AuthSuperadminGuard]
  },
  {
    path: 'add-laundry-item',
    component: AddLaundryItemMasterComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
   canActivate: [AuthSuperadminGuard]
  },
  {
    path: 'floor-master',
    component: FloorMasterComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthSuperadminGuard]
  },
  {
    path: 'add-room-master',
    component: AddRoomMasterComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
   canActivate: [AuthSuperadminGuard]
  },
  {
    path: 'add-tax-master',
    component: AddTaxMasterComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
   canActivate: [AuthSuperadminGuard]
  },
  {
    path: 'add-Food-master',
    component: AddFoodMasterComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
   canActivate: [AuthSuperadminGuard]
  },
  {
    path: 'create-user',
    component: CreateUserComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
   canActivate: [AuthSuperadminGuard]
  },
  {
    path: 'add-item-master',
    component: AddItemMasterComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
   canActivate: [AuthSuperadminGuard]
  },
  {
    path: 'add-rate-type',
    component: AddRateTypeComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
   canActivate: [AuthSuperadminGuard]
  },
  
  {
    path: 'invoice',
    component: InvoiceListComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
   canActivate: [AuthSuperadminGuard]
  },

  {
    path: 'front-desk',
    component: FrontDeskComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthSuperadminGuard]
  },

  {
    path: 'checkout',
    component: CheckoutComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthSuperadminGuard]
  },

  {
    path: 'checkin',
    component: CheckinComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthSuperadminGuard]
  },

  {
    path: 'edit-checkin',
    component: EditCheckinComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthSuperadminGuard]
  },


   {
    path: 'edit-reservation/:id',
    component: EditReservationComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthSuperadminGuard]
  },

  {
    path: 'extra-service',
    component: ExtraServiceComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthSuperadminGuard]
  },
  {
    path: 'food-service',
    component:FoodServiceComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthSuperadminGuard]
  },

  {
    path: 'laundry-service',
    component:LaundryServiceComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthSuperadminGuard]
  },
  {
    path: 'checkin/:date/:room-type',
    component: CheckinComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthSuperadminGuard]
  },


  {
    path: 'reservation',
    component: ReservationComponent,
    children: [
      {
        path: '',
        component: SiteHeaderComponent,
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthSuperadminGuard]
  },

  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: ''
  // },

  /*{
    path: 'reset-password/:id',
   // component: ResetPasswordComponent
  },*/

  // otherwise redirect to home
  

  {
    path: 'login',
    component: LoginComponent
  },
  // otherwise redirect to home
  // {
  //   path: '**',
  //   redirectTo: ''
  // }
];

export const routing = RouterModule.forRoot(appRoutes);
