// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
 appName: "hotel",
  production: false,
  
  
  apiUrl: 'https://guesthouse-api.manipal.edu/',
  // apiUrl: 'http://3.6.99.136:3000/',

  oneSignalAppId: 'c382baff-142f-4e16-b7c4-f6133d400ea4', //dev
  media_domain: 'https://d18b12j5kjbabo.cloudfront.net/production',//dev
  apiPrefix: 'api/v1',
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
