<site-header></site-header>

<div class="page-container create_user_cont flt">
    <div class="page-content-wrapper">
        <div class="page-content">
            <div class="page-bar">
                <div class="page-title-breadcrumb">
                    <div class="card-head">
                        <div class="page-title">Create User</div>
                    </div>
                </div>
            </div>

            <div class="page_content flt">
                <div class="row">
                    <div class="col-sm-3">

                        <div class="card-box flt">
                            <div class="card-body flt">
                                <form [formGroup]="userForm" #myForm="ngForm" (ngSubmit)="onSubmit()">
                                    <div
                                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                        <select class="mdl-textfield__input" id="txtRoomNo" name="gender"
                                            (change)="selectchange($event)" formControlName="roll"
                                            [ngClass]="{ 'is-invalid': (f.roll.touched || submitted) && f.roll.errors }"
                                            placeholder="Gender" autocomplete="off">
                                            <option value="">Select Roll</option>
                                            <ng-container>
                                                <option value="2">
                                                    Hr
                                                </option>
                                                <option value="1">
                                                    Student Institute
                                                </option>
                                            </ng-container>
                                        </select>
                                        <label class="mdl-textfield__label">Roll</label>
                                        <div *ngIf="(f.roll.touched || submitted) && f.roll.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.roll.errors?.required">Roll is required</div>
                                        </div>
                                    </div>
                                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                                        *ngIf="institute&&!maheinst">
                                        <select class="mdl-textfield__input" id="institutte" name="gender"
                                            formControlName="institution" (change)="selectinstitute($event)"
                                            [ngClass]="{ 'is-invalid': (f.institution.touched || submitted) && f.institution.errors }"
                                            placeholder="Gender" autocomplete="off">
                                            <option value="">Select Institution</option>
                                            <ng-container *ngFor="let ins of $institute; let x=index">
                                                <option  value="{{ins.institute_code}}">
                                                    {{ins.institute_name}}
                                                </option>
                                            </ng-container>
                                        </select>
                                        <label class="mdl-textfield__label">Institution</label>
                                        <div *ngIf="(f.institution.touched || submitted) && f.institution.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.institution.errors?.required">Institution is required</div>
                                        </div>
                                    </div>
                                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                                    *ngIf="institute&&maheinst">
                                    <select class="mdl-textfield__input" id="institutte" name="gender"
                                        formControlName="institution" (change)="selectinstitute($event)"
                                        [ngClass]="{ 'is-invalid': (f.institution.touched || submitted) && f.institution.errors }"
                                        placeholder="Gender" autocomplete="off">
                                        <option value="">Select Institution</option>
                                        <ng-container *ngFor="let ins of $institute; let x=index">
                                            <option [selected]="ins.institute_code=='MAHEU'" value="{{ins.institute_code}}">
                                                {{ins.institute_name}}
                                            </option>
                                        </ng-container>
                                    </select>
                                    <label class="mdl-textfield__label">Institution</label>
                                    <div *ngIf="(f.institution.touched || submitted) && f.institution.errors?.required"
                                        class="invalid-feedback">
                                        <div *ngIf="f.institution.errors?.required">Institution is required</div>
                                    </div>
                                </div>

                                    <div *ngIf="departmentvalue&&roll=='2'"
                                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                        <select class="mdl-textfield__input" id="txtRoomNo" name="gender"
                                            formControlName="department"
                                            [ngClass]="{ 'is-invalid': (f.department.touched || submitted) && f.department.errors }"
                                            placeholder="Gender" autocomplete="off">
                                            <option value="">Select Department</option>
                                            <ng-container *ngFor="let dep of $department; let x=index">
                                                <option [value]="dep.code">
                                                    {{dep.name}}
                                                </option>
                                            </ng-container>
                                        </select>
                                        <label class="mdl-textfield__label">Department</label>
                                        <div *ngIf="(f.department.touched || submitted) && f.department.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.department.errors?.required">Department is required</div>
                                        </div>
                                    </div>

                                    <div class="form-group flt">
                                        <div
                                            class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                            <input class="mdl-textfield__input" type="text" formControlName="name"
                                                id="txtRateType" autocomplete="off"
                                                [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors }">
                                            <label class="mdl-textfield__label">Name</label>
                                        </div>
                                        <div class="invalid">
                                            <div *ngIf="(f.name.touched || submitted) && f.name.errors?.required"
                                                class="invalid-feedback">
                                                <div *ngIf="f.name.errors?.required">Name is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group flt">
                                        <div
                                            class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                            <input class="mdl-textfield__input" type="text"
                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                maxlength="10" formControlName="mobile_number" id="txtRateType"
                                                autocomplete="off" minlength="10"
                                                [ngClass]="{ 'is-invalid': (f.mobile_number.touched || submitted) && f.mobile_number.errors }">
                                            <label class="mdl-textfield__label">Mobile Number</label>
                                        </div>
                                        <div class="invalid">
                                            <div *ngIf="(f.mobile_number.touched || submitted) && f.mobile_number.errors?.required"
                                                class="invalid-feedback">
                                                <div *ngIf="f.mobile_number.errors?.required">Mobile Number is required
                                                </div>
                                            </div>
                                            <div *ngIf="f.mobile_number.errors?.minlength" class="invalid-feedback">
                                                <div *ngIf="f.mobile_number.errors?.minlength">Enter minimum 10 digits
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group flt">
                                        <div
                                            class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                            <input class="mdl-textfield__input" type="text" formControlName="email"
                                                id="txtRateType" autocomplete="off"
                                                [ngClass]="{ 'is-invalid': (f.email.touched || submitted) && f.email.errors }">
                                            <label class="mdl-textfield__label">Email</label>
                                        </div>
                                        <div class="invalid">
                                            <div *ngIf="(f.email.touched || submitted) && f.email.errors?.required"
                                                class="invalid-feedback">
                                                <div *ngIf="f.email.errors?.required">Email is required</div>
                                            </div>
                                            <div *ngIf="(f.email.touched || submitted) && f.email.errors?.email"
                                                class="invalid-feedback">
                                                <div *ngIf="f.email.errors?.email">Please enter
                                                    valid email</div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="form_submit flt">
                            <a (click)="onSubmit()">Submit</a> &nbsp;&nbsp;
                            <a class="cancel_cls" (click)="cancel()" *ngIf="isEdit">Cancel</a>
                        </div>
                    </div>
                    <div class="col-sm-9">
                        <div class="card-box">
                            <div class="card-body">
                                <div class="convacation_filter_head flt"></div>
                                <div>
                                    <div class="row">
                                        <div class="col-sm-5">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <input class="mdl-textfield__input" type="text" autocomplete="off"
                                                    id="search1" (keyup)="search($event)"
                                                    placeholder="Search by name, mobile no.">
                                                <label class="mdl-textfield__label">Serch by name, mobile no,
                                                    institution, department</label>
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-5">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <select class="mdl-textfield__input institute_select" id="select"
                                                    name="gender" formControlName="institution" placeholder="Gender"
                                                    autocomplete="off" (change)="changeFilter($event)">
                                                    <option value="">Select Institution</option>
                                                    <ng-container *ngFor="let ins of $institute; let x=index">
                                                        <option [value]="ins.institute_code">
                                                            {{ins.institute_name}}
                                                        </option>
                                                    </ng-container>
                                                </select>
                                                <label class="mdl-textfield__label">Institution</label>

                                            </div>
                                        </div> -->
                                        <div class="col-sm-2">
                                            <div class="reset_btn flt">
                                                <button (click)="resetFilter($event)">Reset</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="card-box">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table display product-overview mb-30"
                                        style="width:1200px;min-width:100%">
                                        <thead style="background-color: #167CE4; color: white;">
                                            <tr class="mdl-data-table__cell--non-numeric">
                                                <th style="width:200px">SI. No</th>
                                                <th style="width:500px">Roll</th>
                                                <th style="width:600px">Institution</th>
                                                <th style="width:600px">Department</th>
                                                <th style="width:400px">Name</th>
                                                <th style="width:200px">Mobile</th>
                                                <th style="width:300px">Email</th>
                                                <th style="width:200px">Action</th>
                                            </tr>
                                        </thead>


                                        <tbody *ngIf="$user&&$user.length">
                                            <tr *ngFor="let list of $user | paginate : {
                                    itemsPerPage: pageSize,
                                    currentPage: cur_page,
                                    totalItems: totalcount}; let x=index">
                                                <td>{{ perPage * (cur_page - 1) + x + 1 }}</td>
                                                <td *ngIf="list.role_id==1">Student Institute</td>
                                                <td *ngIf="list.role_id==2">HR</td>
                                                <td *ngIf="list.institute_name">{{list.institute_name}}</td>
                                                <td *ngIf="!list.institute_name">-</td>
                                                <td *ngIf="list.department_name">{{list.department_name}}</td>
                                                <td *ngIf="!list.department_name">-</td>
                                                <td>{{list.name}}</td>
                                                <td *ngIf="list.mobile_number">{{list.mobile_number}}</td>
                                                <td *ngIf="!list.mobile_number">-</td>
                                                <td>{{list.email}}</td>
                                                <td class="action_type">
                                                    <a (click)="editUser($event,list)"><i
                                                            class="fa-solid fa-pencil"></i></a>
                                                    <a (click)="deleteUser($event,list)"><i
                                                            class="fas fa-trash-alt"></i></a>
                                                </td>
                                            </tr>


                                        </tbody>

                                    </table>
                                    <div class="no_data" *ngIf="$user&&!$user.length">
                                        No Data
                                    </div>
                                </div>

                                <div class="pagination_flex">
                                    <p>Showing {{pagingCounter}} to {{cur_page*10}} of {{totalcount}} entries</p>
                                    <pagination-controls previousLabel="Prev" nextLabel="Next"
                                        *ngIf="$user && $user.length" responsive="true"
                                        (pageChange)="changePage($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<site-footer></site-footer>