import { Component, OnInit,AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray,FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService, RoomService } from '../_services';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Subject } from 'rxjs';
import{FloorService} from '../_services/floor.service'
declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-laundry-service',
  templateUrl: './laundry-service.component.html',
  styleUrls: ['./laundry-service.component.css']
})
export class LaundryServiceComponent implements OnInit {
  checkinForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  mobile_no = '';
rsrdate: any;
rmcat: any;
roomcat: any;
$reservation: any;
reservationId: any;
roomId: any;
rm_id : any;
image: any;
curPage = 1;
perPage = 10;
fileToUpload1: Blob;
fileToUpload2: Blob;
rmno_data: any;
 imageChangedEvent: any = '';
 roomnumber: any;
 image1:any;
 image2:any;

 croppedImage: any = '';
 myFile: Blob;
 $rooms:any;
 roomDetails:any;
 checkinid:any;
 phone1:any;
 checkindetails:any;
 orderid :any;
item$:any;
checkindate:any;
laundryservice$:any;
name:any;
room_type:any;
room_number:any;
checkoutdate:any;
roomnu:any;
billnu:any;
dtOptions: DataTables.Settings = {};
dtTrigger: Subject<any> = new Subject();
uniqueId:any;
  constructor( 
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private roomSer: RoomService,
    private FloorService:FloorService) { }

  ngOnInit(): void {
    const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
    this.checkinForm = this.formBuilder.group({
 
          delivery_date:['',[Validators.required]],
          delivery_time:['',[Validators.required]],
          laundry_name:['',[Validators.required]],
           quantity:['',[Validators.required]],
           rate:['',[Validators.required]],
           amount:['',[Validators.required]],
         
 
       });


       this.route.queryParams.subscribe(params => {
      this.orderid = params['id']; 
      
    });
this.FloorService.getlaundry().subscribe(data=>{

this.item$=data.data.Laundry;
  //console.log(this.item$)
})
this.dtOptions = {
  pagingType: 'full_numbers',
  processing: true,
  pageLength: 10,
  language: {
    searchPlaceholder: "Name"
  },
  columnDefs: [{
    searchable: false,
    orderable: false,
    targets: [6],
    
  }],
  // order: [[ 2, 'asc' ]]
};

   this.roomSer.getCheckinSingle(this.orderid).subscribe(data => {
   this.checkindetails=data.data.checkin_details;
   this.checkinid=this.checkindetails[0]._id;
   this.phone1=this.checkindetails[0].phone;
   this.name=this.checkindetails[0].name;
   this.checkindate=this.checkindetails[0].booking_date;
    this.checkoutdate=this.checkindetails[0].checkout_date;
   this.room_number=this.checkindetails && this.checkindetails[0].rooms && this.checkindetails[0].rooms && this.checkindetails[0].rooms[0].room_number?this.checkindetails[0].rooms[0].room_number:'';
   this.room_type=this.checkindetails && this.checkindetails[0].room_type && this.checkindetails[0].room_type && this.checkindetails[0].room_type[0].room_type?this.checkindetails[0].room_type[0].room_type:'';
   this.roomnu= this.checkindetails[0].room_no;
   this.uniqueId=this.checkindetails[0].uniqueId;
  //  alert(this.checkindate)
   //console.log(this.checkindetails)
  //  this.checkinForm.patchValue({
        
  //  name:this.checkindetails && this.checkindetails[0].name?this.checkindetails[0].name:'' 
  //  })

  var cdate=this.checkindate;
      
  var mdate=this.checkoutdate;
  //console.log(cdate)
  document.getElementById("rdate").setAttribute("min", cdate);
  document.getElementById("rdate").setAttribute("max", mdate);
   })

  //  this.roomSer.getLaundryservice(this.uniqueId).subscribe(data=>{

        
  //   this.laundryservice$=data.data;
  //   this.dtTrigger.next();
    

  //   })

  
  }
  get f() { return this.checkinForm.controls; }

  setSelectedTime1(e){
  
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

foodselect(e){

this.FloorService.getLaundrys(e.target.value).subscribe(data=>{
  //console.log(data)
  this.checkinForm.get('rate').setValue(data.data.price)
  })
}

countAmount(e){
 if(this.f.rate.value)
 {
  var amount=e.target.value*this.f.rate.value;
  this.checkinForm.get('amount').setValue(amount)
}
else{
this.toastr.error("please select laundry item name");
this.checkinForm.get('quantity').setValue('');
}

}

  onSubmit(form, formWrap){
   
      this.submitted = true
      //console.log(this.checkinForm)
    if(this.checkinForm.invalid){
      this.toastr.error("Please enter all fields")
      return false;
    }
    var sdate=this.f.delivery_date.value+this.f.delivery_time.value;
   
    // alert(sdate)
    //console.log(Date.now())
    if (sdate < Date.now()) {
      alert("The Date must be Bigger or Equal to today date")
      return false;
  }

    Swal.fire({
      title: 'Are you sure want to add laundry service?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
    if(result.isConfirmed){
      var that = this;
  
      that.roomSer.laundryservice(
        that.orderid,
        that.phone1,
        that.roomnu,
        that.f.laundry_name.value,
        that.f.quantity.value,
        that.f.rate.value,
        that.f.amount.value,
        that.f.delivery_date.value,
        that.f.delivery_time.value,
        that.uniqueId,
       )
        .subscribe(
            data => {
               if (data.status.code == 0) {
                        that.submitted = false;
                        document.body.classList.remove('jw-modal-open');
                        that.loading = false;
                        that.toastr.success(data.status.message);
                        that.checkinForm.setValue({
                          delivery_date:'',
                          delivery_time:'',
                          laundry_name:'',
                          quantity:'',
                          rate:'',
                          amount:'',
                        })
                  
                        formWrap.reset();
                        setTimeout(function () {
                          $("#support_table5").dataTable().fnDestroy();
                          that.roomSer.getLaundryservice(that.uniqueId).subscribe(data=>{
                
                            //console.log(data)
                            that.laundryservice$=data.data;
                            that.dtTrigger.next();
                            })
                        }, 1000);
                    } else {
                        document.body.classList.remove('jw-modal-open');
                        that.loading = false;
                        that.toastr.error(data.status.message)
                    }
            },
            error => {

                that.toastr.error(error)
                document.body.classList.remove('jw-modal-open');
                that.loading = false;
            });
  
  
      }
      else if (result.isDenied) {
        Swal.fire(
          'Cancelled!',
        )
      }
     });



 
    
  
  }

  datechange(e){
    var that = this;
    var st='';
    var en='';
 st=that.f.fromdate.value
 en=that.f.todate.value 
   if(st && en){
// To calculate the time difference of two dates 
if(st<=en){
var start =  new Date(that.f.fromdate.value);
var end = new Date(that.f.todate.value);

var Difference_In_Time = end.getTime() - start.getTime(); 


var days = Difference_In_Time / (1000*3600*24); 
  
   /* var days = that.end.getTime() - that.start.getTime(); 
    //console.log(days)*/
  //  var days = (that.end - that.start) / (1000  60  60 * 24);
   if(days == 0){
     days = 1;
    
     that.checkinForm.get('totalday').setValue(days)
   }
   else{
     days = days + 1;
    
     that.checkinForm.get('totalday').setValue(days)
   }
  }else
  {
    that.toastr.error("From date not greater than to date");
    that.checkinForm.get('fromdate').setValue('')
    that.checkinForm.get('todate').setValue('')
  }
  }
  else{
  //  that.toastr.error("please select from date");
  //  that.checkinForm.get('todate').setValue('')
  }
  }

deleteExtraService(e,user,i){
//console.log(user)
  Swal.fire({
    title: 'Are you sure want to delete laundry service?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }).then((result) => {
  if(result.isConfirmed){
  var that=this;
  that.roomSer.deleteLaundry(user._id).subscribe(data => {
      if (data.status.code == 0) {
        that.toastr.success('Deleted Successfully')
        // alertify.success('Deleted Successfully')
        setTimeout(function () {
          $("#support_table5").dataTable().fnDestroy();
          that.roomSer.getLaundryservice(that.uniqueId).subscribe(data=>{

            //console.log(data)
            that.laundryservice$=data.data;
            that.dtTrigger.next();
            })
        }, 1000);
        //that.users$.splice(i, 1);
      } else {
        that.toastr.error(data.status.message)
        // alertify.error(data.status.message)
        //this.alertService.error(data.status.message);
      }
    },
    error => {
      that.toastr.error(error)
      // alertify.error(error)
      //this.alertService.error(error);
    });
  }
  else if (result.isDenied) {
    Swal.fire(
      'Cancelled!',
    )
  }
 });
}
cancel(){
  this.router.navigateByUrl("/front-desk");
}

  ngAfterViewInit(){

    $(document).on('focus','input,select,textarea',function(e){ 
      var cur = $(this);
      $('input,select,textarea').parent().removeClass('is-focused');
      cur.parent()[0].classList.remove('is-focused');
      
      // cur.parent()[0].removeClass('is-focused')
      
        cur.parent()[0].classList.add('is-focused')
       })
    var that=this;
    
    setTimeout(function () {
    
  
      $("#support_table5").dataTable().fnDestroy();
      


        that.roomSer.getCheckinSingle(that.orderid).subscribe(data => {
          that.checkindetails=data.data.checkin_details;
          that.checkinid=that.checkindetails[0]._id;
          that.phone1=that.checkindetails[0].phone;
          that.checkindate=that.checkindetails[0].arrival_date;
          that.checkoutdate=that.checkindetails[0].checkout_date;
          // that.room_number=that.checkindetails && that.checkindetails[0].rooms && that.checkindetails[0].rooms && this.checkindetails[0].rooms[0].room_number?this.checkindetails[0].rooms[0].room_number:'';
          // that.room_type=that.checkindetails && that.checkindetails[0].room_type && that.checkindetails[0].room_type && this.checkindetails[0].room_type[0].room_type?this.checkindetails[0].room_type[0].room_type:'';
        //console.log(that.checkindate)
        var cdate=that.checkindate;
      
        var mdate=that.checkoutdate;
        that.uniqueId=that.checkindetails[0].uniqueId;
        that.roomSer.getLaundryservice(that.uniqueId).subscribe(data=>{

        
          that.laundryservice$=data.data;
          that.dtTrigger.next();
          
      
          })
        //console.log(cdate)
        document.getElementById("rdate").setAttribute("min", cdate);
        document.getElementById("rdate").setAttribute("max", mdate);

         
          })
  

    }, 200);



    }




}

