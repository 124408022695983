import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';
@Injectable()
export class AuthSuperadminGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log(localStorage.getItem(`${environment.appName}` + '_user'))
        if (localStorage.getItem(`${environment.appName}` + '_user')) {
            // logged in so return true
    
            return true;
        }

      
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
       
        return false;
    }
}


