<site-header></site-header>
<div class="page-container" *ngIf="!viewDetails">
    <div class="page-content-wrapper">
        <div class="page-content">
            <div class="page-bar">
                <div class="page-title-breadcrumb">
                    <div class="card-head">
                        <div class="page-title">Online Bookings</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <div class="card  card-box">
                        <div class="state-overview">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="row equal_width">
                                    <div class="col-xl-3 col-md-6 col-12" style="cursor: pointer;"
                                            (click)="adminapproval($event)">
                                            <div class="info-box bg-yellow">
                                                <span class="info-box-icon push-bottom"><i
                                                        class="material-icons">style</i></span>
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Admin <br/>Approvals</span>
                                                    <div class="progress">
                                                    </div>
                                                    <span class="info-box-number">{{adminapprovalscount ?
                                                        adminapprovalscount : '0'}}</span>
                                                    
                                                    <span class="progress-description">
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-md-6 col-12" style="cursor: pointer;"
                                            (click)="pending($event)">
                                            <div class="info-box bg-orange">
                                                <span class="info-box-icon push-bottom"><i
                                                        class="material-icons">card_travel</i></span>
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Superadmin/Admin<br/>Pending<br/>
                                                     </span>
                                                    <div class="progress">
                                                    </div>
                                                    <span class="info-box-number">{{pendingCount ?
                                                        pendingCount : '0'}}</span>
                                                    
                                                    <span class="progress-description">
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-md-6 col-12" style="cursor: pointer;"
                                            (click)="approve($event)">
                                            <div class="info-box bg-blue">
                                                <span class="info-box-icon push-bottom"><i
                                                        class="material-icons">style</i></span>
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Superadmin <br/>Approvals</span>
                                                    <div class="progress">
                                                    </div>
                                                    <span class="info-box-number">{{approveCount ?
                                                        approveCount : '0'}}</span>
                                                    
                                                    <span class="progress-description">
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-3 col-md-6 col-12" style="cursor: pointer;"
                                            (click)="reject($event)">
                                            <div class="info-box bg-red">
                                                <span class="info-box-icon push-bottom"><i
                                                        class="material-icons">report_problem</i></span>
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Superadmin/Admin<br/>Rejected &nbsp;</span>
                                                    <div class="progress">
                                                    </div>
                                                    <span class="info-box-number">{{rejectCount ?
                                                        rejectCount : '0'}}</span>
                                                   
                                                    <span class="progress-description">
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row search_cls">
                                <div class="col-sm-4"> <input type="text" class="form-control seachinput"
                                        placeholder="Search by Name, Mobile No." (keyup)="search($event)" id="search"/></div>
                                <div class="col-sm-6"></div>
                                <div class="col-sm-2"><span class="count_no">Total Count : <b>{{totalcount}}</b></span></div>
                            </div>
                            <div class="table-wrap">
                                <div class="table-responsive">
                                    <table cellspacing="0" id="table" style="width:2000px;min-width:100%"
                                        class="table table-hover dataTable table-striped w-full">
                                        <thead style="background-color: #167CE4; color: white;">
                                            <tr>
                                                <th>Sl No</th>
                                                <th>Name</th>
                                                <th>Contact No</th>
                                                <th>Checkin</th>
                                                <th>Checkout</th>
                                                <th>No. of Days</th>
                                                <th>No. of Guests</th>
                                                <th>No. of Rooms</th>
                                                <th *ngIf="status!='pendingFromBoth'&&status=='onlyAdminApproved'">Admin Approved By</th>
                                                <th *ngIf="status!='pendingFromBoth'&&status=='bothApproved'"> Approved By</th>
                                                <th *ngIf="status!='pendingFromBoth'&&status=='rejectedFromAny'"> Rejected By</th>
                                               
                                                <th *ngIf="status!='pendingFromBoth'">Institution Name</th>
                                                <th *ngIf="status!='pendingFromBoth'">Department Name</th>
                                                <th *ngIf="status!=0&&status=='rejectedFromAny'"> Rejected Reason</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let user of onlinebook$ | paginate : {
                                                    itemsPerPage: pageSize,
                                                    currentPage: cur_page,
                                                    totalItems: totalcount
                                                  }; let i = index">
                                                <td>{{ perPage * (cur_page - 1) + i + 1 }}</td>
                                                <td>{{user.requestor_details.name ? user.requestor_details.name : '-'}}
                                                </td>
                                                <td>{{user.requestor_details.contact_number ? user.requestor_details.contact_number : '-'}}
                                                </td>
                                                <td>{{user.arrival_date | date:"dd/MM/yyyy"}} {{user.arrival_time}}</td>
                                                <td>{{user.departure_date | date:"dd/MM/yyyy" }} {{user.departure_time}}</td>
                                                <td>{{user.number_of_days ? user.number_of_days : '0'}}</td>
                                                <td>{{user.number_of_guests ? user.number_of_guests : '0'}}</td>
                                                <td>{{user.number_of_rooms ? user.number_of_rooms : '0'}}</td>
                                                <!-- <td *ngIf="user.instituteDetails.length&&user.approved_status != '0'">{{user.instituteDetails[0]
                                                    ? user.instituteDetails[0].name : '0'}}</td>
                                                    <td *ngIf="!user.instituteDetails.length&&user.approved_status != '0'">-</td> -->
                                                    <td *ngIf="user.adminUserDetails.length&&status=='onlyAdminApproved'">{{user.adminUserDetails[0]
                                                        ? user.adminUserDetails[0].name : '0'}}</td>
                                                        <td *ngIf="!user.adminUserDetails.length&&status=='onlyAdminApproved'">-</td>
                                                <td *ngIf="user.adminUserDetails.length&&user.superadmin_approved_status != '0'&&!user.superadmin_approved_by">{{user.adminUserDetails[0]
                                                    ? user.adminUserDetails[0].name : '0'}}</td>
                                                    <td *ngIf="!user.adminUserDetails.length&&user.superadmin_approved_status != '0'&&user.superadmin_approved_by">Superadmin</td>
                                                    <td *ngIf="!user.adminUserDetails.length&&user.superadmin_approved_status != '0'&&!user.superadmin_approved_by">-</td>
                                                    <td *ngIf="user.adminUserDetails.length&&status=='onlyAdminApproved'"><span *ngIf="user.adminUserDetails[0].instituteDetails&&user.adminUserDetails[0].instituteDetails.length">{{user.adminUserDetails[0].instituteDetails[0]
                                                        ? user.adminUserDetails[0].instituteDetails[0].institute_name
                                                        : '0'}}</span>
                                                        <span *ngIf="user.adminUserDetails[0].instituteDetails&&!user.adminUserDetails[0].instituteDetails.length">-</span></td>
                                                        <td *ngIf="user.adminUserDetails.length&&status=='onlyAdminApproved'"><span *ngIf="user.adminUserDetails[0].departmentDetails&&user.adminUserDetails[0].departmentDetails.length">{{user.adminUserDetails[0].departmentDetails[0]
                                                            ? user.adminUserDetails[0].departmentDetails[0].departmentName
                                                            : '0'}}</span>
                                                            <span *ngIf="user.adminUserDetails[0].departmentDetails&&!user.adminUserDetails[0].departmentDetails.length">-</span></td>


                                                    <td *ngIf="user.adminUserDetails.length&&user.superadmin_approved_status != '0'&&!user.superadmin_approved_by"><span *ngIf="user.adminUserDetails[0].instituteDetails&&user.adminUserDetails[0].instituteDetails.length">{{user.adminUserDetails[0].instituteDetails[0]
                                                        ? user.adminUserDetails[0].instituteDetails[0].institute_name
                                                        : '0'}}</span>
                                                        <span *ngIf="user.adminUserDetails[0].instituteDetails&&!user.adminUserDetails[0].instituteDetails.length">-</span></td>

                                                        <td *ngIf="!user.adminUserDetails.length&&user.superadmin_approved_status != '0'&&user.superadmin_approved_by">-</td>
                                                        <td *ngIf="user.adminUserDetails.length&&user.superadmin_approved_status != '0'&&!user.superadmin_approved_by"><span *ngIf="user.adminUserDetails[0].departmentDetails&&user.adminUserDetails[0].departmentDetails.length">{{user.adminUserDetails[0].departmentDetails[0]
                                                            ? user.adminUserDetails[0].departmentDetails[0].departmentName
                                                            : '0'}}</span>
                                                            <span *ngIf="user.adminUserDetails[0].departmentDetails&&!user.adminUserDetails[0].departmentDetails.length">-</span></td>

                                                            <td *ngIf="!user.adminUserDetails.length&&user.superadmin_approved_status != '0'&&user.superadmin_approved_by">-</td>


                                                        <td style="width:300px;" *ngIf="status!='pendingFromBoth'&&status=='rejectedFromAny'"><span *ngIf="user.reject_reason">{{user.reject_reason}}</span>
                                                            <span *ngIf="!user.reject_reason">-</span></td>
                                                        <!-- <td *ngIf="!user.adminUserDetails.length&&user.superadmin_approved_status != '0'">-</td> -->
                                                   
                                                <td>
                                                    <button class="btn btn-success" *ngIf="user.superadmin_approved_status == '0'" style="cursor: pointer;" (click)="statusChange(user, 1)">Approve</button>
                                                    <button class="btn btn-danger" *ngIf="user.superadmin_approved_status == '0' || user.superadmin_approved_status == '1'" style="cursor: pointer;" (click)="statusChange(user, 2)">Reject</button>
                                                <span *ngIf="user.superadmin_approved_status == '2'">Rejected</span>
                                                </td>
                                                <td style="width:100px;"><a (click)="view(user)">
                                                        <li class="fa fa-eye">
                                                    </a>
                                                    <a (click)="delete(user)">
                                                        <li class="fa fa-trash">
                                                    </a>
                                                    <a (click)="download(user)" *ngIf="user.superadmin_approved_status != '0'">
                                                        <li class="fa fa-download">
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div *ngIf="onlinebook$ && !onlinebook$.length">
                                        <div class="no_data">No data found</div>
                                    </div>
                                    
                                </div>
                                <div class="paginate" *ngIf="onlinebook$ && onlinebook$.length">
                                    <p>Showing {{pagingCounter}} to {{cur_page*10}} of {{totalcount}} entries</p>
                                    <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true"
                                        (pageChange)="changePage($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="page-container" *ngIf="viewDetails">

    <div class="page-content-wrapper">
        <div class="page-content">
            <div class="page-bar">
                <div class="page-title-breadcrumb">
                    <div class="card-head">
                        <div class="page-title">Online Bookings</div>
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-sm-12"><button class="btn btn-danger float-right" (click)="back($event)"><i
                            class="fa fa-arrow-left"></i>Back</button></div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="card card-box">
                        <span class="txt_cls">Requestor Details</span>
                        <ul class="booking_list">
                            <li>Name <span>:</span></li>
                            <li> {{editUser.requestor_details.name ? editUser.requestor_details.name : '-'}}</li>
                            <li>Contact Number <span>:</span></li>
                            <li> {{editUser.requestor_details.contact_number ? editUser.requestor_details.contact_number : '-'}}</li>
                            <li>Email<span>:</span></li>
                            <li> {{editUser.requestor_details.email ? editUser.requestor_details.email : '-'}}</li>
                            <li *ngIf="editUser.requestor_details.role_type!='STUDENT'">Department<span>:</span></li>
                            <li *ngIf="editUser.requestor_details.role_type!='STUDENT'"> {{editUser.requestor_details.department ? editUser.requestor_details.department : '-'}}</li>
                            <li>Institution<span>:</span></li>
                            <li> {{editUser.requestor_details.institution ? editUser.requestor_details.institution : '-'}}</li>
                            
                            <li>Employee Code <span>:</span></li>
                            <li> {{editUser.requestor_details.employee_code ? editUser.requestor_details.employee_code : '-'}}</li>
                            <li>Address <span>:</span></li>
                            <li> {{editUser.requestor_details.address ? editUser.requestor_details.address : '-'}}</li>
                        </ul>
                        <span class="txt_cls">Guest Details</span>
                        <ul class="booking_list">
                            <li>Name <span>:</span></li>
                            <li> {{editUser.guest_details.name ? editUser.guest_details.name : '-'}}</li>
                            <li>Contact Number <span>:</span></li>
                            <li> {{editUser.guest_details.contact_number ? editUser.guest_details.contact_number : '-'}}</li>
                            <li>Relationship <span>:</span></li>
                            <li> {{editUser.guest_details.relationship ? editUser.guest_details.relationship : '-'}}</li>
                            <li>Address <span>:</span></li>
                            <li> {{editUser.guest_details.address ? editUser.guest_details.address : '-'}}</li>
                        </ul>
                        
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="card card-box">
                        <span class="txt_cls">Other Details</span>
                        <ul class="booking_list">
                            <li>Checkin Date <span>:</span></li>
                            <li> {{editUser.arrival_date | date:"dd/MM/yyyy"}}</li>
                            <li>Checkin Time <span>:</span></li>
                            <li> {{editUser.arrival_time}}</li>
                            <li>Checkout Date <span>:</span></li>
                            <li> {{editUser.departure_date | date:"dd/MM/yyyy"}}</li>
                            <li>Checkout Time <span>:</span></li>
                            <li> {{editUser.departure_time}}</li>
                            <li>Include GST <span>:</span></li>
                            <li> {{editUser.include_gst == 1 ? 'Yes' : 'No'}}</li>
                            <li>No. of Days<span>:</span></li>
                            <li> {{editUser.number_of_days ? editUser.number_of_days : '0'}}</li>
                            <li>No. of Guests<span>:</span></li>
                            <li> {{editUser.number_of_guests ? editUser.number_of_guests : '0'}}</li>
                            <li>No. of Rooms<span>:</span></li>
                            <li> {{editUser.number_of_rooms ? editUser.number_of_rooms : '0'}}</li>
                            <li>Purpose of visit<span>:</span></li>
                            <li> {{editUser.purpose_of_visit ? editUser.purpose_of_visit : '-'}}</li>
                            <li *ngIf="editUser.gst_details">GST Number <span>:</span></li>
                            <li *ngIf="editUser.gst_details"> {{editUser.gst_details.gst_number ? editUser.gst_details.gst_number : '-'}}</li>
                             <li *ngIf="editUser.gst_details">Company Name <span>:</span></li>
                            <li *ngIf="editUser.gst_details"> {{editUser.gst_details.company_name ? editUser.gst_details.company_name : '-'}}</li>
                            <li *ngIf="editUser.gst_details">Address <span>:</span></li>
                            <li *ngIf="editUser.gst_details"> {{editUser.gst_details.address ? editUser.gst_details.address : '-'}}</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-4" *ngIf="editUser.image_file">
                    <div class="card card-box">
                        <span class="txt_cls">Image</span>
                       <img src="{{media_domain}}{{editUser.image_file}}" (click)="viewImage(editUser)">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<site-footer></site-footer>
<div class="modal fade" id="myModal7" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"
              (click)="closeImage(editUser)">×</span></button>
          <div class="status_box flt">
            <label>Image</label>
        
             <div class="profile_image"><img src="{{media_domain}}{{pan_img}}" /></div>
             <!-- <div *ngIf="!profileimage" class="profile_image"><img  src="assets/images/defaultp.png" alt="image"></div> -->
        
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade reject_reason" id="myModal4" role="dialog">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <form [formGroup]="rejectForm" >
                <div class="reject_reason">
                    <label>Reason for Rejection: </label>
                    <textarea rows="5" formControlName="reason"> </textarea>
                    
                </div>
                <div class="invalid">
                    <div *ngIf="(f.reason.touched || submitted) && f.reason.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.reason.errors?.required">Reason is required</div>
                    </div>

                </div>
                </form>
                <div class="reject_butn">
                    <button class="reject_cancel" data-dismiss="modal" (click)="cancel()">Cancel</button>
                    <button class="reject_sub" (click)="submit()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>