<div class="page-header navbar navbar-fixed-top flex noPrint">

    <div class="page-header-inner ">
        <div class="page-logo">
            <a routerLink="/front-desk">
                <img src="assets/images/logo.png">
            </a>
        </div>


        <a href="javascript:;" class="menu-toggler responsive-toggler" data-toggle="collapse"
            data-target=".navbar-collapse">
            <span></span>
        </a>
    </div>
    <div class="navbar-custom">
        <div class="hor-menu hidden-sm hidden-xs">
            <ul class="nav navbar-nav">
                <li class="mega-menu-dropdown  " [ngClass]="{'active':routeParam=='/dashboard'}">
                    <a routerLink="/dashboard" class="dropdown-toggle"> <i
                            class="material-icons">dashboard</i>&nbsp;Dashboard&nbsp;
                    </a>
                </li>



                <li class="mega-menu-dropdown" [ngClass]="{'active':routeParam=='/front-desk' || routeParam=='/'}">
                    <a routerLink="/front-desk" class="dropdown-toggle"> <span class="material-icons">
                            desktop_mac
                        </span>&nbsp;Front Desk&nbsp;

                        <span class="arrow "></span>
                    </a>

                </li>


                <li class="mega-menu-dropdown"  [ngClass]="{'active':routeParam=='/online-book'}">
                    <a routerLink="/online-book" class="dropdown-toggle"> <span class="material-icons">
                            pageview
                        </span>&nbsp;Online Bookings&nbsp;

                        <span class="arrow "></span>
                    </a>

                </li>

                <li class="mega-menu-dropdown"
                    [ngClass]="{'active':routeParam=='/checkin' || routeParam==checkinactive}">
                    <a routerLink="/checkin" class="dropdown-toggle"><span class="material-icons">
                            spellcheck
                        </span>&nbsp;Check In&nbsp;
                        <span class="arrow "></span>
                    </a>
                </li>


                <li class="mega-menu-dropdown"
                    [ngClass]="{'active':routeParam=='/reservation' || routeParam==reserveactive}">
                    <a routerLink="/reservation" class="dropdown-toggle"><span class="material-icons">
                            playlist_add_check
                        </span>&nbsp;Reservation&nbsp;
                        <span class="arrow "></span>
                    </a>
                </li>
                <li class="mega-menu-dropdown"
                [ngClass]="{'active':routeParam=='/create-user' || routeParam==reserveactive}">
                <a routerLink="/create-user" class="dropdown-toggle"><span class="material-icons">
                        playlist_add_check
                    </span>&nbsp;Create User&nbsp;
                    <span class="arrow "></span>
                </a>
            </li>

                <li class="mega-menu-dropdown" [ngClass]="{'active':routeParam=='/invoice' }">
                    <a routerLink="/invoice" class="dropdown-toggle"><span class="material-icons">
                            payment
                        </span>&nbsp;Invoice&nbsp;
                        <span class="arrow "></span>
                    </a>
                </li>

                <li class="mega-menu-dropdown "
                    [ngClass]="{'active':routeParam=='/floor-master' || routeParam=='/add-room-type' || routeParam=='/add-room-master' || routeParam=='/add-tax-master' || routeParam=='/add-item-master' || routeParam=='/add-rate-type' }">
                    <a class="dropdown-toggle"><span class="material-icons">
                            settings
                        </span> &nbsp;Setting&nbsp;
                        <i class="fa fa-angle-down"></i>


                    </a>
                    <ul class="dropdown-menu">
                        <li>
                            <div class="mega-menu-content">
                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="mega-menu-submenu">
                                            <li>
                                                <a routerLink="/floor-master" class="nav-link ">
                                                    <span class="title">Floor
                                                        Master</span></a>
                                            </li>
                                            <li>
                                                <a routerLink="/add-room-type" class="nav-link ">
                                                    <span class="title">Room Type Master</span></a>
                                            </li>
                                            <li>
                                                <a routerLink="/add-room-master" class="nav-link ">
                                                    <span class="title">Room Master</span></a>
                                            </li>
                                            <li>
                                                <a routerLink="/add-tax-master" class="nav-link ">
                                                    <span class="title">Tax Master</span></a>
                                            </li>
                                            <li>
                                                <a routerLink="/add-item-master" class="nav-link ">
                                                    <span class="title">Item Master</span></a>
                                            </li>
                                            <li>
                                                <a routerLink="/add-rate-type" class="nav-link "> <span
                                                        class="title">Rate
                                                        Type</span></a>
                                            </li>

                                            <li>
                                                <a routerLink="/add-Food-master" class="nav-link "> <span
                                                        class="title">Food Master</span></a>
                                            </li>
                                            <li>
                                                <a routerLink="/add-laundry-item" class="nav-link "> <span
                                                        class="title">Laundry Item Master</span></a>
                                            </li>


                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
                <li class="mega-menu-dropdown ">
                    <a class="dropdown-toggle"><i class="fa fa-user-circle-o"></i>
                        <span class="username username-hide-on-mobile"> Admin </span>
                        <i class="fa fa-angle-down"></i>


                    </a>
                    <ul class="dropdown-menu">
                        <li>
                            <div class="mega-menu-content">
                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="mega-menu-submenu">
                                            <li>
                                                <a (click)="logout()">
                                                    <i class="icon-logout"></i> Log Out </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>

            </ul>
        </div>
    </div>
</div>